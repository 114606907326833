@import "./colors.css";
/* Specific side Menu CSS */

.tagEditorContent {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.addIcon {
  color: #2196f3;
}

.previewContent {
  padding: 0px;
}

.copyTo {
  font-size: 12px;
  cursor: pointer;
  border: 1px dashed;
  padding: 5px;
}

.copySweepId {
  float: right;
}

.copyUrl {
  float: left;
}

.copy {
  border-left: 1px dashed;
  padding: 5px;
  margin-left: 5px;
  font-weight: 700;
}

.copyUrl:hover {
  color: var(--primary--color);
}

.colorPreview {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  margin-left: 2rem;
}

.tagActionMenu {
  width: 2rem;
  margin-right: 5px;
}

.contentTabs {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

.visibilityOff {
  color: var(--declined--color);
}

.visibilityOn {
  color: var(--approved--color);
}

.enabled_mouse_icon {
  font-size: 1.4rem;
  color: var(--primary--color);
}

.disabled_mouse_icon {
  font-size: 1.4rem;
  color: grey;
}

.tagTypeIcons {
  font-size: 1.4rem;
}

.addLink {
  font-size: 13px;
  color: var(--primary--color);
  cursor: pointer;
}

.TagOptionGrid {
  margin-top: 10px !important;
}

.groupingTag{
  margin-top: 25px;
}

.groupingTagSelector>div.filter__control {
  min-height: 32px !important;
}

.groupingTagSelector>div.filter__control>div.filter__value-container>div.filter__multi-value {
  height: 25px;
}