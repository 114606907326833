.header__container {
    display: flex;
    justify-content: space-between;
}

.header__buttons_container {
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
}

.header__title_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 10px;
}

.header__counter {
    color: #555555;
}