.searchbar__container {
    width: 100%;
    height: auto;
    padding: 1rem;
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    background: whitesmoke;
    box-shadow: 0px 0px 2.1px 2px #eaeaeac4;
}

.searchbar__filters_and_refresh_container {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
}

.searchbar__select, .searchbar__input, .searchbar__input input {
    min-width: 250px !important;
    height: 40px !important;
}

.searchbar__filter_title {
    font-size: 13px;
    color: #555555;
    font-weight: 600;
    margin-bottom: .4rem;
}

.searchbar__buttons_container {
    display: flex;
    gap: .5rem;
    padding-left: 0;
    padding-right: .5rem;
    justify-content: right;
    flex-wrap: wrap;
    width: 100%;
}

.searchbar__buttons_container .orbital_button_outline {
    padding: 0.3rem .5rem 0.3rem .5rem;
}