/* FileTable.css */
.thumbnail {
    width: 32px;
    height: 32px;
  }
  
  .type-icon {
    font-size: 1.5rem;
    color: #007bff; /* Meaningful color for type icons */
    cursor: pointer;
  }
  
  .action-edit-icon {
    font-size: 1.2rem;
    margin: 5px;
    color: #3f73aa; /* Meaningful color for action icons */
    cursor: pointer;
  }
  
  .action-edit-icon:hover {
    color: #007bff; /* Change color on hover */
    transform: scale(1.2); /* Increase size on hover */
  }

  .action-delete-icon {
    font-size: 1.2rem;
    margin: 5px;
    color: #9e3b45; /* Meaningful color for action icons */
    cursor: pointer;
  }
  
  .action-delete-icon:hover {
    color: #dc3545; /* Change color on hover */
    transform: scale(1.2); /* Increase size on hover */
  }
  
  .renderedTableRows > td {
    padding: 10px !important;
  }

  .addMediaRow {
    /* margin-bottom: 1rem; */
    padding: 5px;
  }

  .headerBg {
    /* border-radius: 0.5rem; */
    background: whitesmoke;
    box-shadow: 0px 0px 2.1px 2px #eaeaeac4;
    padding: 10px;
  }

  .tableTitleStyle {
    font-size: 14px;
    font-weight: 400;
  }

  .cardBoarderStyle {
    padding-top: 0px !important;
  }