.orbital_column_without_padding {
    padding: 0 !important;
    margin: 0 !important;
}

.orbital_row_with_gap {
    gap: .5rem;
    margin-bottom: .2rem;
}

.question__row {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 45px;
    gap: 0.25rem;
    margin-bottom: 1rem;
}

.question__title {
    font-size: 1rem;
    font-weight: 600;
    color: #555555;
}

.question__item_title {
    font-size: .95rem;
    font-weight: 400;
    color: gray;
}