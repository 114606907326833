.click-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  align-self: center;
  z-index: 1;
  top: 0px;
}

.matterport_preview_section {
  padding-bottom: 1rem;
}

.matterport_preview_section #containerOverlay {
  overflow: hidden;
  border-radius: 0.5rem;
  border: 0.2rem solid #fcfcfc;
  padding: 0.2rem;
  box-shadow: 0px 0px 3.1px 1.1px #d5d5d58c;
}

.matterport_preview_section #showcase {
  border-radius: 0.3rem;
}    