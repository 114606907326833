.tags_modal__header {
    display: flex;
    justify-content: space-between;
    /* background: transparent; */
    /* border-bottom: 1px solid #f3f3f3; */
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    align-items: center;
}

.tags_modal__title {
    color: #555555;
}

.tags_modal_header_buttons_container {
    display: flex;
    gap: 0.5rem;
}
