.first_row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.form_object__title {
    margin-bottom: 3px;
    font-size: 1rem;
    font-weight: 400;
}

.icon_preview {
    background: #f0f0f0;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
}

.groupingTags {
    margin-top: 1rem;
}

.ReactTags__tagInputField {
    width: 100%;
    border-radius: 0.3rem;
}

.ReactTags__selected {
    display: flex;
}

.ReactTags__selected span.ReactTags__tag {
    opacity: 1;
    align-items: center;
    cursor: move;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.2rem;
    background: #2173b3;
    padding: 0 0.4rem 0 0.4rem;
}

.ReactTags__tagInput, .ReactTags__tags {
    width: 100%
}

.ow_matterport__select_box_with_differentOptions {
    border: 1px solid #ced4da;
    border-radius: 3px;
    padding: 3px 2px 3px 10px;
    width: 100%;
    height: 100%;
    color: gray;
    position: relative;
}

.ow_matterport__select_box_with_differentOptions:hover {
    cursor: default;
}

.ow_matterport__select_box_with_differentOptions_text {
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.ow_matterport__select_box_with_differentOptions_icon {
    font-size: 1.1rem;
    color: #495057b3;
}

.ow_matterport__select_box_with_differentOptions_submenu_bottom {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ced4da;
    background: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3px;
    border-radius: 0.25rem;
    gap: 0.25rem;
    z-index: 1;
    max-height: 12rem;
    overflow: auto;
}


.ow_matterport__select_box_with_differentOptions_submenu_top {
    position: absolute;
    bottom: 100%;
    left: 0;
    border: 1px solid #ced4da;
    background: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3px;
    border-radius: 0.25rem;
    gap: 0.25rem;
    z-index: 1;
    max-height: 12rem;
    overflow: auto;
}

.ow_matterport__select_box_with_differentOptions_submenu_top::-webkit-scrollbar,
.ow_matterport__select_box_with_differentOptions_submenu_bottom::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ow_matterport__select_box_with_differentOptions_submenu_top,
.ow_matterport__select_box_with_differentOptions_submenu_bottom {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.ow_matterport__select_box_with_differentOptions_option {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.ow_matterport__select_box_with_differentOptions_option:hover {
    background-color: #2a94e5;
    color: white;
}

.ow_matterport__select_box_with_differentOptions_option {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.ow_matterport__select_box_with_differentOptions_option_color_preview {
    width: 1rem;
    height: 1rem;
    border-radius: 0.2rem;
}

.groupingTags_title {
    margin-top: 1rem;
}