.ow_matterport__component_loader__container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
}

.ow_matterport__component_loader__spinner_container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8e8e875;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    border-radius: 1rem;
    height: fit-content;
    box-shadow: 0px 0px 2px 1px #e4e4e48c;
}