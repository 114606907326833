.orbital_button_primary {
    display: flex !important;
    gap: .5rem !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #2a94e5 !important;
    border-color: #2a94e5 !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    /* width: 2rem !important;
    height: 2rem !important; */
    padding: .5rem 1rem .5rem 1rem !important;
    font-size: 1.1rem !important;
}
.orbital_button_primary:hover,
.orbital_button_primary:focus,
.orbital_button_primary:active {
    background-color: #2a97eb !important;
    border-color: #2a97eb !important;
}

.orbital_button_outline {
    display: flex !important;
    gap: .5rem !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10rem;
    background-color: transparent !important;
    border-color: #2a94e5 !important;
    color: #2a94e5 !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    /* width: 2rem !important;
    height: 2rem !important; */
    padding: .5rem 1rem .5rem 1rem !important;
    font-size: 1.1rem !important;
}

.orbital_button_outline:hover,
.orbital_button_outline:focus,
.orbital_button_outline:active {
    background-color: #2a97eb !important;
    border-color: #2a97eb !important;
    color: white !important;
}

.orbital_table__body_row {
    opacity: 0.6;
}

.orbital_table__body_row:hover {
    opacity: 1;
}

.orbital_button_transparent {
    background-color: transparent !important;
    font-size: 1.5rem !important;
    color: #2a94e5 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 0px !important;
    padding: 0px !important;
    outline: none !important;
    border-radius: 0px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    height: 100%;
}

.orbital_button_transparent:hover,
.orbital_button_transparent:focus,
.orbital_button_transparent:active,
.orbital_button_transparent:checked,
.orbital_button_transparent:visited {
    color: #2a94e5;
    background-color: transparent;
    outline: none !important;
    border: 0px !important;
    box-shadow: none !important;
}
