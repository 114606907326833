.orbital_tabs__container {
    gap: 0.5rem;
}

.orbital_tabs__nav_link_active,
.orbital_tabs__nav_link:hover {
    padding: 0.3rem 0.6rem 0.3rem 0.6rem;
    color: white;
    background-color: #2a94e5;
    opacity: 1;
}

.orbital_tabs__nav_link_active > a,
.orbital_tabs__nav_link_active:hover > a,
.orbital_tabs__nav_link:hover > a {
    color: #fff;
}

.orbital_tabs__nav_link {
    padding: 0.3rem 0.6rem 0.3rem 0.6rem;
    background: #e0e0e0;
    opacity: .5;
    color: gray;
}

.orbital_tabs__nav_link > a {
    color: gray;
}