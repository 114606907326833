@import "./colors.css";

/* general */
.floatRight {
  float: right;
}

.screenHeight {
  height: 95vh;
  overflow-y: scroll;
}
.screenHeight::-webkit-scrollbar {
  display: none;
}
.screenHeight {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.pointer:hover {
  cursor: pointer;
}

.clickableIcon {
  cursor: pointer;
}

.clickableIcon:hover {
  color: #2196f3c4 !important;
}

.npPadding {
  padding: 0px !important;
}

.noSpace {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paddingLeft1 {
  padding-left: 1rem !important;
}

.paddingLeft2 {
  padding-left: 2rem !important;
}

.noPad2 > div {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.noPad2 {
  margin-bottom: 3px !important;
}

.capitalize {
  text-transform: capitalize;
}

.edit_header_icon_save {
  cursor: pointer;
  margin-right: 15px;
  font-size: 1.5rem;
  color: var(--primary--color);
}

.edit_header_icon_close {
  cursor: pointer;
  font-size: 1.5rem;
  color: grey;
}

.edit_header_icon_add {
  cursor: pointer;
  margin-right: 15px;
  vertical-align: sub;
  font-size: 1.5rem;
  color: var(--primary--color);
}

.languageFlag {
  height: 15px;
}

.info_icon {
  color: var(--primary--color);
  font-size: 0.8rem;
  padding-bottom: 1px;
}

.transparent_color {
  color: rgb(0, 0, 0, 0);
}
/* end general */

/* breadcrumb */
.breadcrumb {
  width: 100% !important;
}

.breadcrumbFix {
  width: 100% !important;
  position: unset !important;
  border: 0px solid white !important;
  margin-bottom: 0px !important;
}

.breadcrumbFix > ol {
  position: unset !important;
  border: 0px solid white !important;
  margin-bottom: 0px !important;
}

.breadcrumbContent {
  height: 50px;
  /* position: fixed; */
}

.breadcrumb-item > a {
  text-decoration: none;
}

.breadcrumb {
  width: 100% !important;
  background-color: #fff !important;
}
/* end breadcrumb */

/* matterport list */
.scene_search_icon {
  border: 1px solid #ced4da !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.activity_list_panel {
  padding-top: 0.5rem;
  height: 100vh;
}

.activityCard {
  padding: 8px;
  font-size: 14px;
}

.bordered-activity {
  border: 1px solid var(--primary--color) !important;
}

.dropdown_icon {
  font-size: 1.2rem;
  color: #6c757d;
}

.addLogo_icon {
  font-size: 1.2rem;
  color: #6c757d;
  margin-right: 7px;
  cursor: pointer;
}

.removeLogo_icon {
  font-size: 1.2rem;
  color: red;
  margin-right: 7px;
  cursor: pointer;
}

.linksCard {
  margin-top: 2rem;
}

.linkCode {
  min-height: 40px;
  margin-top: 0.5rem;
}

.doc_icon {
  font-size: 1.2rem;
  margin-right: 10px;
  cursor: pointer;
  color: #6c757d;
}

.doc_icon:hover {
  color: var(--primary--color);
}

.link-activity {
  border: 1px solid var(--primary--color);
  color: var(--primary--color);
}

.logoUploadText {
  color: var(--primary--color);
  cursor: pointer;
}

.logoUploadTextDisabled {
  color: var(--disabled--color);
  cursor: not-allowed;
}

.side_menu_setting_icon_disabled {
  color: var(--disabled--color);
  cursor: not-allowed;
  font-size: 1.5rem;
}

.configurationTitle {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.configurationTitle .orbital_button_outline {
  border-radius: 0.25rem;
  font-size: 1.5rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
}

.shareCardBody {
  overflow: auto;
}

.generalConfigCard {
  overflow: unset !important;
}

/* end matterport main */

.mpMainBodyContainer {
  padding-right: 0px !important;
  padding-left: 2px !important;
  padding-top: 5px !important;
}

.no_poi_selection {
  margin-top: 15rem;
}

.configuration_tab_section_distance {
  margin-top: 1rem;
}

.general_setting_row_space {
  margin-top: 1rem;
}

.log_setting_row_space {
  margin-top: 1rem;
}

.logo_position_buttons {
  float: right;
  width: 100%;
}

.logo_position_buttons2 {
  float: right;
  width: 100%;
  margin-top: 5px;
}

.audio_setting_row_space {
  margin-top: 1rem;
}

.interaction_setting_row_space {
  margin-top: 1rem;
}

.side_menu_searchbar {
  border:  1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.75rem;
}

.side_menu_setting_icons {
  float: right;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.side_menu_setting_icons .orbital_button_outline,
.configureEnableBlock .orbital_button_outline {
  font-size: 0.8rem;
  height: 1.8rem;
}

.side_menu_setting_icon {
  color: var(--primary--color);
  cursor: pointer;
  font-size: 0.5rem;
}

.side_menu_setting_icon_space {
  margin-left: 0.5rem;
}

.side_menu_setting_table_header {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
    margin-right: 0;
}

.search_tags {
  height: 32px !important;
  font-size: 14px;
  /* font-size: 22px; */
}

.matterport_preview_section {
  margin-top: 1rem;
}

.matterport_tag_table {
  margin-top: 0.2rem;
}

.custom_color_picker {
  height: 1.5rem;
  width: 50%;
  margin-top: 0.2rem;
  border-radius: 100px;
}

.custom_color_picker .form-control {
  cursor: pointer;
  padding: 0;
  border: none;
  height: 100%;
  width: 100%;
}

.side_menu_color_picker_col {
  padding-left: 0px !important;
}

.custom_sm_switch .custom-control-label::before {
  cursor: pointer;
}

.custom_sm_switch .custom-switch .custom-control-label::after {
  cursor: pointer;
}

.custom_sm_switch
  .custom-control-input:checked
  ~ .custom-control-label::before {
  cursor: pointer;
}

.custom-control {
  z-index: 0 !important;
}

.edit_tag_specific_tabs {
  margin-top: 1rem;
}

.popup_dimension_col {
  padding-left: 0px !important;
}

/* custom */

.noPad > div {
  padding: 10px !important;
}

.noPad2 > div {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.noPad2 {
  margin-bottom: 3px !important;
}

.languageBox,
.optionBox,
.shareBox {
  margin: 0px;
  padding-bottom: 7px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.3;
  font-weight: 500;
  color: #a0a0a0;
}

.hoverColor:hover {
  background-color: #047afb24;
}

.languageBox:hover,
.optionBox:hover,
.shareBox {
  opacity: 0.85;
}

.option-text-container {
  padding: 0.5rem 0.2rem 0.5rem 0.2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
}


.option-text-container:hover {
  background-color: #2a94e5;
  color: white;
}


.shoppingPreviewModal {
  width: 80%;
  max-width: 80%;
  border-radius: 0px;
  border: 0px;
}

.shoppingPreviewModal > .modal-content {
  border-radius: 0px;
  border: 0px;
  min-height: 600px;
  max-height: 700px;
}

.mainView {
  overflow-x: hidden;
  display: unset;
}

.tagActionMenu {
  color: white;
  border-color: #2a94e5;
  background-color: #2a94e5;
}

.tagActionMenu:hover {
  border-color: #007bff;
  background-color: #007bff;
}

.popover-header {
  background: transparent;
  border: 0px;
  font-size: 14px;
  color: #b0b0b0;
}

.popover {
  z-index: 1040 !important;
}

.ow_matterport__download_button_container {
  padding-top: 33px;
}

.card {
  border-radius: 0 !important;
}

.ow_matterport_breadcrumb__right_container {
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  padding-right: 3rem;
}

.ow_matterport__breadcrumb_other_menu_button {
  padding: 0.4rem 0.75rem 0.4rem 0.75rem !important;
}

.ow_matterport__right_container_configuration {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.ow_matterport__tab_container {
  margin-top: 20px !important;
}

.ow_matterport__no_data_container {
  display: flex;
    justify-content: center;
    align-items: center;
    min-height: 15rem;
    border-radius: 0.5rem;
    border: 0.2rem solid #e9e9e93b;
    box-shadow: 0px 0px 3.1px 1.1px #d5d5d540;
    background: #f5f5f5;
    color: #d3d1d1;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.ow_matterport__no_data_icon {
  font-size: 1.5rem;
}

.tag-view-panel-header, 
.carousel-bullets, 
.icon-button .button-interactive .modal-nav .modal-prev, 
.icon-button .button-interactive .modal-nav .modal-next {
 display: none !important;
}

.ow_matterport__select_auto_size {
  max-width: 300px;
  min-width: 200px !important;
}

.ow_matterport_table_column_center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ow_matterport_table_column_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.ow_matterport_table_column_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.ow_matterport__dnd_icon_and_content {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.ow_matterport__dnd_icon {
  opacity: .3;
  font-size: 1.3rem;
  padding-left:0;
  color: gray;
}

.ow_matterport__download_input_col {
  display: flex;
  gap: .5rem;
  align-items: flex-start;
}

.ow_matterport__download_input_form_group {
  height: 100%;
  width: 100%;
}

.ow_matterport__list_group_without_border {
      border: 1px solid #d8d8d8 !important;
    /* padding: 0.75rem 0rem !important; */
    margin-top: 1rem;
    border-radius: 0.3rem;
    background: #f7f7f75c;
}

.containerLeft .pro-sidebar.collapsed {
  width: 1rem !important;
}