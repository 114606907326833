.DnDContainer .menu_item_row {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border: 1px solid #d8d8d8;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.65rem;
    margin-top: 0.65rem;
    background-color: #f7f7f7;
    border-radius: 0.3rem;
        padding-left: 1rem;
    padding-right: 1rem;
    
}

.list-group .DnDContainer .menu_item_row {
    margin-bottom: 0rem;
    
}

.list-group .DnDContainer .menu_item_row .row {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.list-group .DnDContainer .menu_item_row h5 {
        height: 100%;
    display: flex;
    align-items: center;
}

.DnDContainer:hover {
    cursor: row-resize;
}

.DnDIcon {
    cursor: move;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}