.ow_matterport__3D_preview_container_library {
    border: 2px solid;
    padding: 0.5rem;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #f1f1f1;
    border-radius: 1.2rem
}

.ow_matterport__card_library,
.ow_matterport__card_library_iframe {
    padding: .2rem;
    max-width: 100%;
    min-width: fit-content;
    border-radius: .5rem !important;
    box-shadow: 0px 0px 5px 2px #e4e4e452;
    border: 1px solid #ededed;
    transition: .5s;
    position: relative;
}

.ow_matterport__card_library_iframe:hover {
    cursor: pointer;
    border: 1px solid #2a97eb;
}

.ow_matterport__preview_model_info_list_container {
    padding: .5rem 1.5rem;
}