.ow_matterport__3D_preview_container {
    border: 2px solid;
    border-radius: 1rem;
    padding: 0.5rem;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #f1f1f1;
    box-shadow: 0 0 3px 0.3px #d9d9d95c;
        color: #d0d0d0;
}