.sideMenuItemAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.videoItem {
    max-height: 200px;
}

.menuItemTitle {
    padding-top: 0.5rem;
    padding-left: 1rem;
}

.menuItemTitle>h5 {
    font-size: 17px;
}

.menuItemBody {
    padding-left: 2rem;
    padding-bottom: .5rem;
    
}

.menuItemBody ul {
    
    padding-left: 1rem;
}

.sideMenuContent {
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.sideMenuContent textarea {
    overflow-y: auto;
    overflow-x: hidden;
}

.tagActionMenu {
    width: 2rem;
    margin-right: 5px;
}

.contentTabs {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
}

.sweepCopyView {
    padding: 0px !important;
    padding-bottom: 1rem !important;
}

.configureEnableBlock {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-items: center;
}

.sideMenuConfigurationIcon {
    margin-right: 10px;
    color: var(--primary--color);
    font-size: 1.2rem;
    cursor: pointer;
}

.menuConfigBlocks {
    margin-bottom: 20px;
}

.socialInputs{
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ow_matterport__social_column {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
}


.deleteStepIcon {
    color: red;
}

.addIcon {
    color: #2196f3;
}